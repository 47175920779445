<template>
  <div class="outpatient_container">
    <div v-if="notice" class="mask"></div>
    <div v-if="notice" class="popup">
      <h1>门诊缴费注意事项</h1>
      <p>1.自助缴费暂只支持自费，如需医保报销请到人工收费窗口缴费！</p>
      <p>2.自助缴费成功后如需退检查费、检验费、退药费均到人工收费窗口办理</p>
      <p>3.如需发票请到人工收费窗口打印</p>
      <el-button type="primary" @click.native="ok">我知道了</el-button>
    </div>
    <el-row class="outpatient_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <el-row class="outpatient_tip">
      <h1>温馨提示：请点击需要缴费的项目(默认查询七天内的缴费信息)</h1>
    </el-row>
    <!-- <el-input v-model="cs" placeholder="cesssss"></el-input>

    <el-button type="primary" @click="cesss"></el-button> -->
    <div class="outpatient_main">
      <el-table v-loading="loading" :data="recipeData" border="" height="70%" style="width: 100%" @row-click="on_select">
        <el-table-column label="医生名称" prop="YSMC"></el-table-column>
        <el-table-column label="科室" prop="KSMC"></el-table-column>
        <el-table-column label="处方时间" prop="CFRQ"></el-table-column>
        <el-table-column label="项目合计金额（元）" prop="JE"></el-table-column>
      </el-table>
    </div>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button v-if="!notice" class="home" type="primary" @click.native="home">首页</el-button>
    <!-- <el-button type="danger"
               class="pay"
               @click.native="pay"
               v-if="!notice">确定支付</el-button> -->
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'
import { getOutPatientFeeList } from '@/service/outpatient'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      notice: false,
      cs: '',
      tableData: [],
      query: {},
      orderNum: '',
      totalAmout: '',
      recipeData: [],
      loading: false
    }
  },
  created() {
    this.name = this.$route.query.name
    this.query = this.$route.query
    this.query.MZH = '00000043'
    this.getClinicPayFeeInfo()
  },
  methods: {
    cesss() {
      this.query.MZH = this.cs
      this.getClinicPayFeeInfo()
    },
    async getClinicPayFeeInfo() {
      this.loading = true
      let data = {
        MZH: this.query.MZH,
        TYPE: 0,
        TS: 7
      }
      const res = await getOutPatientFeeList(data)
      console.log(res)
      if (res.data.code == 0) {
        this.loading = false
        if (Array.isArray(res.data.data.DATASET.ROW)) {
          this.recipeData = res.data.data.DATASET.ROW
        } else {
          let item = []
          item.push(res.data.data.DATASET.ROW)
          this.recipeData = item
        }
      } else {
        this.loading = false
        this.$message({
          message: res.data.MSG,
          type: 'error'
        })
      }
    },
    on_select(val) {
      console.log(val)
      const params = {
        SFID: val.SFID,
        CFRQ: val.CFRQ,
        LSH: val.LSH,
        JE: val.JE,
        MZH: this.query.MZH,
        name: this.name,
        patName: this.query.name,
        cardNo: this.query.cardNo,
        patCardNo: this.query.patCardNo,
        patCardType: this.query.patCardType
      }
      this.$router.push({
        path: '/outpatientDetail',
        query: params
      })
    },
    ok() {
      this.notice = false
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    pay() {
      if (!this.outpatient) {
        this.$message({
          message: '暂无缴费项目',
          type: 'warning'
        })
        return
      }
      let _this = this
      const params = {
        name: this.name,
        patCardNo: this.query.patCardNo,
        patCardType: this.query.patCardType,
        cost: parseFloat(this.totalAmout),
        orderNum: this.orderNum,
        recordType: '8',
        patName: this.query.patName
      }

      this.$router.push({
        path: '/mode',
        query: params
      })
    }
  }
}
</script>
<style lang="less">
.outpatient_container {
  position: relative;
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.outpatient_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 1;
}

.outpatient_container .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 60%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.outpatient_container .popup h1 {
  text-align: center;
  letter-spacing: 10px;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.outpatient_container .popup p {
  margin-bottom: 20px;
  color: red;
  font-size: 28px;
  line-height: 40px;
}

.outpatient_container .popup .el-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px !important;
}

.outpatient_main {
  width: 90%;
  height: 70%;
  margin: 0 auto;
}

.outpatient_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.outpatient_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}

.outpatient_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.outpatient_tip h1 {
  margin: 30px 0;
  font-weight: 700;
  color: #43e97b;
  text-align: center;
  font-size: 40px;
}

.outpatient_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.outpatient_info .left {
  float: left;
  width: 50%;
}

.outpatient_info .right {
  float: right;
  width: 50%;
}

.outpatient_info p {
  padding-left: 30%;
  font-size: 26px;
  color: #458ce9;
  margin-bottom: 20px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.outpatient_container .pay {
  position: absolute;
  bottom: 80px;
  right: 150px;
  font-size: 26px;
}
</style>
